
$background-color: white;
$brand-text-color-1: #5E9C8E;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Itim', cursive;
  font-size: 20px;
  scroll-behavior: smooth;
}

body {
  background-color: $background-color;
}

img {
  object-fit: contain;
}

.disableSelect {
  user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
}

.disableDrag {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.navMenu {
  position: absolute;
  width: 100vw;
  margin-top: 20px;
  display: flex;
  color: $brand-text-color-1;
}

.navList {
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.navButton {
  cursor: pointer;
  border-radius: 20px;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 10px;
  padding-right: 10px;
  display: inline;
  background-color: rgba($color: white, $alpha: .5);
}

.navButton a {
  text-decoration: none; /* no underline */
}

$lang-select-font-size: 30px;

#lang-select-box {
  position: fixed;
  bottom: 0;
  left: 0;
  margin-bottom: 15px;
  margin-left: 10px;
  display: flex;
  font-size: $lang-select-font-size;
  cursor: pointer;
}

.lang-select-option {
  color: $brand-text-color-1;
  font-size: $lang-select-font-size;
  margin-left: 5px;
  margin-right: 5px;
}

.opacity-half {
  opacity: .5;
}

.container {
  scroll-snap-type: y mandatory;
  // scroll-snap-type: y proximity; 
  overflow-y: scroll;
  height: 100vh;
}

section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
}

#logoSection {
  display: flex;
  // align-items: center;
  // justify-content: center;
}

#badgeContainer {
  height: 50vh;
  width: 50vh;
  position: relative;
}

#badgeIcon {
  position: absolute;
  width: 100%;
  animation: 1s spin 0s ease-out;
}

#wabaIcon {
  position: absolute;
  width: 50%;
  left: 0; 
  right: 0; 
  top: 0;
  bottom: 0;
  margin-left: auto; 
  margin-right: auto; 
  margin-top: auto;
  margin-bottom: auto;
  scale: 0;
  animation: .75s ease-in-out 1s scaleUp both;
  z-index: 1;
}

@keyframes scaleUp {
  0% {scale: 0;}
  50% {scale: 1.5;}
  100% {scale: 1;}
}

@keyframes scaleLoop {
  0% {scale: 1;}
  100% {scale: 1.05;}
}

@keyframes spin {
  0% {
    scale: 10;
    transform: rotate3d(0,1,0, 180deg);
  }
  100% {
    scale: 1;
    transform: rotate3d(0,1,0, 0deg);
  }
}

#section1 {
  display: flex;
  flex-direction: column;
}

#section2 {
  // background: hsl(50, 100, 50);
}

#section3 {
  // background: hsl(10, 100, 50);
}

#section4 {
  // background: hsl(70, 100, 50);
}

#section5 {
  // background: hsl(90, 100, 50);
}